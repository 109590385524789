<template>
  <div class="church-details template-2">
    <header class="page-header container mx-lg d-flex">
      <h1>{{ translations.tcLocationProfile }}</h1>
      <QuickJump @location_changed="handleLocationChange($event)" :i18n="translations.components"></QuickJump>
    </header>
    <!-- / Page Header -->

    <div class="page-body container mx-lg">
      <div class="details">
        <div class="d-flex row primary">
          <div class="col-lg-4 col-md-4">
            <h4>{{ location.org_name }}</h4>
            <div class="info">
              <p>
                <span v-if="location.address_line_1">
                  {{ location.address_line_1 }}
                  <br />
                </span>
                <span v-if="location.address_line_2">
                  {{ location.address_line_2 }}
                  <br />
                </span>
                <span v-if="location.address_line_3">
                  {{ location.address_line_3 }}
                  <br />
                </span>
                <span v-if="location.city || location.state || location.zip">
                  {{ location.city }}, {{ location.state }} {{ location.zip }}
                  <br />
                </span>
                <span v-if="location.phone_number">{{ location.phone_number_formatted }}</span>
                <span v-if="location.email && location.phone_number">|</span>
                <span v-if="location.email"><a :href="'mailto:' + location.email">{{ location.email }}</a></span>
              </p>
              <img v-if="!!location.location_image && location.location_image !== ''" :src="location.location_image"
                class="profile_image" />
              <camera v-if="!location.location_image || location.location_image === ''"></camera>
              <div v-if="iCanSeeAny(secured_placement_edit_controls)">
                <div v-if="showUpload">
                  <b-form-file class="mb-1 mt-1" style="text-transform: uppercase" ref="fileUploadImg"
                    v-model.lazy="location_image_file" name="location_image" :placeholder="translations.tcImageOptional"
                    :drop-placeholder="translations.tcDropFileHere" :browse-text="translations.tcBrowse" />
                  <button @click="onUpload" variant="primary" size="sm" class="btn btn-primary">
                    {{ translations.tcUploadPhoto }}
                  </button>
                  <button @click="onCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                    {{ translations.tcCancel }}
                  </button>
                </div>
                <div>
                  <b-link @click="showUpload = true" v-if="!showUpload">
                    <span v-if="!location.location_image">{{ translations.tcAddPhoto }}</span>
                    <span v-if="location.location_image">{{ translations.tcUpdatePhoto }}</span>
                  </b-link>
                  <b-link @click="onDelete" v-if="location.location_image && !showUpload" class="ml-2">{{
                    translations.tcDeletePhoto
                  }}</b-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <h4>{{ translations.tcLocationDetails }}</h4>
            <div class="specs">
              <p>
                <strong>{{ translations.tcLocationType }}:</strong>
                {{ institutionType }}
              </p>
              <p>
                <strong>{{ translations.tcMemberType }}:</strong>
                {{ memberType }}
              </p>
              <p>
                <strong>{{ translations.tcMembersRequired }}:</strong>
                <br />
                {{ location.members_required }}
              </p>
              <p>
                <strong>{{ translations.tcOtherInformation }}:</strong>
                <br />
                {{ location.note }}
              </p>
              <router-link :to="{ name: 'location-details' }" class="btn btn-primary">{{
                translations.tcViewMore
              }}</router-link>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <h4>{{ translations.tcPrimaryContact }}</h4>
            <div class="contact">
              <h5>
                <a href="#" v-if="location.contact.name" @click.prevent="handleNameClick(location.contact.key)">{{
                  location.contact.name
                }}</a>
                <span v-else>{{ translations.tcNoContactOnFile }}</span>
              </h5>
              <p>
                <a v-if="location.contact.email" :href="'mailto:' + location.contact.email">{{
                  location.contact.email
                }}</a>
                <span v-else>{{ translations.tcNoEmail }}</span>
                <br />
                {{ location.contact.phone ? location.contact.phone : `${translations.tcNoPhone}` }}
                <br />
                <strong>{{ translations.tcRelationship }}:</strong>
                {{ relationshipType }}
              </p>
            </div>
          </div>
        </div>
        <div class="row d-flex events" v-if="translations.components">
          <div class="col col-4">
            <app-event :obj="lastPlacementObj" :i18n="translations.components"
              @edit_requested="locationStorePlacementKeyAndRedirect($event)"></app-event>
          </div>
          <div class="col col-4">
            <app-event :obj="nextPlacementObj" :i18n="translations.components"
              @edit_requested="locationStorePlacementKeyAndRedirect($event)"></app-event>
          </div>
        </div>
        <div class="d-flex">
          <a @click.prevent="downloadReport(detailedLocationReportUrl, 'DetailedLocationReport', 'pdf')"
            class="btn btn-primary mr-4">{{ translations.tcRunDetailedLocationReport }}</a>
          <router-link v-if="iCanSeeAny(secured_placement_add_controls)" class="btn btn-primary"
            :to="{ name: `add-placement-date` }">{{ translations.tcAddScripturePlacementDate }}</router-link>
        </div>
      </div>
      <div class="actions">
        <div class="card-deck row">
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '4px' }"></div>
            <svg-contact-information></svg-contact-information>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcLocationCommunicationsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcLocationCommunicationsDescription }}
              </p>
              <b-button to="/programs/sd/location-communications" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '5px' }"></div>
            <svg-church-contacts></svg-church-contacts>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcLocationContactsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcLocationContactsDescription }}
              </p>
              <b-button to="/programs/sd/location-contacts" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '0' }"></div>
            <svg-church-details></svg-church-details>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcLocationDetailsTitle }}</h5>
              <p class="card-text text-left">{{ translations.tcLocationDetailsDescription }}</p>
              <b-button to="location-details" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
        </div>
        <div class="card-deck row">
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '11px' }"></div>
            <svg-visits></svg-visits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcPlacementItemsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcPlacementItemsDescription }}
              </p>
              <b-button to="/programs/sd/placement-items" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '15px' }"></div>
            <svg-pres-service-times></svg-pres-service-times>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcScripturePlacementDatesTitle }}</h5>
              <p class="card-text text-left">{{ translations.tcScripturePlacementDatesDescription }}</p>
              <b-button to="/programs/sd/all-placement-dates" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col-md-4">
            <div :style="{ height: '0' }"></div>
            <svg-visits></svg-visits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcVisitNotesTitle }}</h5>
              <p class="card-text text-left">{{ translations.tcVisitNotesDescription }}</p>
              <b-button to="/programs/sd/location-visits" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Page Body -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'
import ChurchContacts from '@/assets/svgs/church-contacts.vue'
import ChurchDetails from '@/assets/svgs/church-details.vue'
import ChurchMembersFriends from '@/assets/svgs/church-members-friends.vue'
import ContactInformation from '@/assets/svgs/contact-information.vue'
import Event from '@/components/Event.vue'
import PresServiceTimes from '@/assets/svgs/presentations-service-times.vue'
import QuickJump from '@/components/quick-jump/QuickJumpLocations.vue'
import { translationMixin } from '@/mixins/translationMixin'
import Visits from '@/assets/svgs/visits.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'location-profile',
  mixins: [translationMixin, reportDownloadMixin],
  data() {
    return {
      translations: {},
      institutionType: '',
      memberType: '',
      relationshipType: '',
      location_image_file: null,
      showUpload: false,
      secured_placement_add_controls: {
        add_aux_facility_placement_date_button: 'c1ce0edd-3cea-49d2-9376-c71df402e8c8',
        add_scripture_placement_facility_dates_button: '13588ae8-9c7b-4b8e-938a-e7eaa7cd7c7f',
      },
      secured_placement_edit_controls: {
        edit_aux_facility_placement_location_button: 'b9501e8f-1bee-4a89-b35e-627f25c9ff97',
        edit_scripture_distribution_placement_location_button: 'a8ec7a34-e8e3-4f54-aa69-100cf6f3f112',
      },
    }
  },
  components: {
    appEvent: Event,
    svgContactInformation: ContactInformation,
    svgChurchContacts: ChurchContacts,
    svgChurchDetails: ChurchDetails,
    svgPresServiceTimes: PresServiceTimes,
    svgVisits: Visits,
    svgChurchMembersFriends: ChurchMembersFriends,
    QuickJump: QuickJump,
    camera: camera,
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      loadPlacementDetailedLocationReportUrl: 'scriptureDistribution/loadPlacementDetailedLocationReportUrl',
      savePhoto: 'scriptureDistribution/savePhoto',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
    }),
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadAddressFormOptions(),
        await this.loadLocationDetails({ org_key: this.userSelectedLocationKey, org_type_key: this.userSelectedOrgTypeKey }),
        await this.loadPlacementDetailedLocationReportUrl(this.userSelectedLocationKey),
        await this.setSelectedPlacementDateKey('')
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    async handleLocationChange() {
      await this.pageLoad().then(() => {
        this.updateDynamicText()
      })
    },
    async handleNameClick(key) {
      await this.setSelectedIndividualKey(key)
      this.$router.push({ name: 'non-member-profile-sd' })
    },
    onFileChanged(event) {
      this.location_image_file = event.target.files[0]
    },
    async onUpload() {
      if (this.location_image_file === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorImageRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
        return
      } else {
        var payload = {
          iim_org_key: this.location.plf_org_key,
          iim_image: null,
          iim_user: this.userIndKey,
          iim_delete_flag: false,
        }
        await (payload.iim_image = this.location_image_file)
        await Promise.all([this.setLoadingStatus(true), await this.savePhoto(payload)]).then((result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result[1] ? 'success' : 'error',
            text: result[1] ? this.translations.tcPhotoSaved : this.translations.tcErrorDuringSave,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.pageLoad()
              this.showUpload = false
            }
          })
        })
      }
    },
    onCancel() {
      this.location_image_file = null
      this.showUpload = false
    },
    async onDelete() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToDeletePhoto,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.deleteThePhoto()
        }
      })
    },
    async locationStorePlacementKeyAndRedirect(data) {
      await this.setSelectedPlacementDateKey(data.mtg_key)
      this.setRouterBackLink(this.$route.name)
      this.$router.push({ name: 'placement-details' })
    },
    async deleteThePhoto() {
      var payload = {
        iim_org_key: this.location.plf_org_key,
        iim_user: this.userIndKey,
        iim_delete_flag: true,
      }
      await Promise.all([this.setLoadingStatus(true), await this.savePhoto(payload)]).then((result) => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result ? this.translations.tcPhotoDeleted : this.translations.tcErrorDuringDelete,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          if (result.value) {
            this.pageLoad()
            this.showUpload = false
          }
        })
      })
    },
    updateDynamicText() {
      if (!this.translations.common) return false

      const memberTypeGuid = this.location.member_participation_type_key
      const instTypeGuid = this.location.org_type_key
      const relationshipTypeGuid = this.location.contact_relationship_key
      const memberType = this.translations.common['member-types'][memberTypeGuid] || ''
      const instType = this.translations.common['placement-facility-types'][instTypeGuid] || ''
      const relationshipType = this.translations.common['individual-relationship-type'][relationshipTypeGuid] || ''

      this.memberType = memberType ? memberType : this.location.member_participation_type
      this.institutionType = instType ? instType : this.location.org_type_name
      this.relationshipType = relationshipType ? relationshipType : this.location.contact_relationship
      return true
    },
  },
  computed: {
    ...mapGetters({
      detailedLocationReportUrl: 'scriptureDistribution/detailedLocationReportUrl',
      iCanSeeAny: 'user/iCanSeeAny',
      location: 'scriptureDistribution/locationDetail',
      locationListCSVUrl: 'scriptureDistribution/locationListCSVUrl',
      locationListPDFUrl: 'scriptureDistribution/locationListPDFUrl',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
    }),
    lastPlacementObj() {
      var obj = {
        title: this.translations.tcLastPlacementDate,
        month: this.location.last_placement_month,
        day: this.location.last_placement_day,
        year: this.location.last_placement_year,
        fullDate: this.location.last_placement_date,
        coordinator: this.location.last_placement_mtg_coordinator,
        start_time: this.location.last_placement_start_time,
        class: '',
        mtg_key: this.location.last_placement_mtg_key,
        editLink: this.location.last_placement_mtg_key ? true : false,
      }
      return obj
    },
    nextPlacementObj() {
      var obj = {
        title: this.translations.tcNextPlacementDate,
        month: this.location.next_placement_month,
        day: this.location.next_placement_day,
        year: this.location.next_placement_year,
        fullDate: this.location.next_placement_date,
        coordinator: this.location.next_placement_mtg_coordinator,
        start_time: this.location.next_placement_start_time,
        class: '',
        mtg_key: this.location.next_placement_mtg_key,
        editLink: this.location.next_placement_mtg_key ? true : false,
      }
      return obj
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      if (!this.userSelectedLocationKey) {
        this.$router.push('/programs/sd/scripture-distribution')
      } else {
        await Promise.all([
          await this.getViewTranslations(),
          await this.getComponentTranslations('event', 'quick-jump-locations'),
          await this.getComponentTranslations(
            'common.individual-relationship-type',
            'common.member-types',
            'common.placement-facility-types'
          ),
          await this.pageLoad(),
        ]).then((results) => {
          this.stripReadableText(results[2])
          const translatedText = {
            ...results[1],
            common: { ...this.translations.common },
          }
          this.$set(this.translations, 'components', translatedText)
          this.updateDynamicText()
        })
      }
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.col-3.date {
  min-height: 118px;
}

.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}

.profile_image {
  max-width: 285px;
  max-height: 185px;
  object-fit: contain;
}

.details {
  margin-bottom: 46px;
  padding: 32px 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);

  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;

      >* {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.primary {
  margin-bottom: 35px;

  >.col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  h5 {
    margin: 0;
    color: #1e68fb;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  strong {
    font-weight: 800;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1.03px;
    line-height: 26px;
  }

  img {
    margin-top: 17px;
  }

  a {
    color: rgba(30, 104, 251, 1);
    text-decoration: underline;

    &:hover {
      color: rgba(30, 104, 251, 0.8);
    }
  }

  .btn {
    color: #fff;
    text-decoration: none;
  }

  img {
    margin: 0;
  }

  .specs {
    p {
      margin-bottom: 10px;
      line-height: 22px;

      &:last-of-type {
        margin-bottom: 17px;
      }
    }
  }

  .contact {
    strong {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.events {
  margin-bottom: 50px;

  .col-4 {
    &:nth-of-type(even) {
      .event .date {
        background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%);
        background-image: linear-gradient(135deg, hsl(194, 100%, 39%) 0%, hsl(198, 68%, 75%) 100%);
      }
    }

    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;

      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }

  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

.actions {
  @include breakpoint(sm) {
    padding: 0 1rem;
  }
}

.card-deck {
  .card {
    width: 100%;

    svg {
      display: block;
      margin: 0 auto 13px;
    }
  }
}

.card-deck.row {
  margin-bottom: 30px;
}
</style>
